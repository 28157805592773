function truncateString(str, n) {
	try {
		if (str.length > n) {
			return str.substring(0, n) + '...';
		} else {
			return str;
		}
	} catch (error) {
		return '';
	}
}

// Takes a JSON argument list and convert it to a Graph QL input string, including flatting arrays
function printString(val) {
	if (arguments.length === 0) {
		return 'null';
	} else if (typeof val === 'undefined') {
		return 'null';
	} else if (val === null) {
		return 'null';
	} else if (typeof val === 'boolean') {
		return val ? 'true' : 'false';
	} else if (typeof val === 'number') {
		return val.toString();
	} else if (typeof val === 'object') {
		var valLength = val.length;
		if (valLength == 0) return '[]';
		let result = '[';
		for (var i = 0; i < valLength; i++) {
			// Last iteration of the array
			if (i + 1 == valLength) {
				result = result + '"' + val[i] + '"]';
			} else {
				result = result + '"' + val[i] + '",';
			}
		}
		return result;
	} else if (typeof val === 'string') {
		return val;
	} else {
		return val;
	}
}

function toTitleCase(str) {
	return str.replace(/\w\S*/g, function(txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
}

function prepareGQLInput(val) {
	let gql_string = '';
	let is_first = true;
	for (const [key, value] of Object.entries(val)) {
		if (is_first == true) {
			gql_string = key + ' : ' + printString(value);
			is_first = false;
		} else {
			gql_string = gql_string + ' , ' + key + ' : ' + printString(value);
		}
	}
	return gql_string;
}

exports.truncateString = truncateString;
exports.printString = printString;
exports.prepareGQLInput = prepareGQLInput;
exports.toTitleCase = toTitleCase;
