import React from 'react';
import { Box, Grid, Typography, Chip,useTheme } from '@material-ui/core';
import { CardProduct } from 'components/organisms';
import { Image } from 'components/atoms';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { truncateString } from 'helpers/helpers-string';
import LanguageIcon from '@material-ui/icons/Language';
import BusinessIcon from '@material-ui/icons/Business';
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby";


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    zIndex: 'auto',
  },
  image: {
    objectFit: 'cover',
  },
  sdgImage: {
    objectFit: 'contain',
  },
  sdgContainer: {
    height: '40px',
    width: '40px',
    margin: 2,
  },
  chipArea: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  brandText: {
    marginTop: '-25px',
  },
  iconRoot: {
    verticalAlign: 'middle',

  },
}));

export default function CardSolution({
  id,
  images,
  name,
  description,
  sdgs,
  industries,
  stakeholders,
  themes,
  solution_types,
  entity,
  className,
}: CardSolutionProps) {
  const classes = useStyles();
  const theme = useTheme();

  const entityPath: string = `/details/solution/${id}`

  var geography : string
  var geography_id : string

  try {
    geography = entity[0].geographies.data.[0].name;
    geography_id = entity[0].geographies.data.[0]._id;

  } catch {
    geography = null;
  }

  const color_chip = sdgs[0] ? sdgs[0].color_code_hex : theme.palette.primary.main

  return (
    <Box marginBottom={2} padding={2}>
      <CardProduct
        withShadow
        liftUp
        style={{ maxWidth: 500 }}
        mediaContent={
          <>
          {solution_types[0] ? 
           <Link
            to={'/all-solutions'}
            state={{ solution_types: [`${solution_types[0]._id}`] }}>
            <div
              style={{
                position: 'absolute',
                left: 8,
                top: 16,
                zIndex: 9,
                background: 'white',
                padding: '4px 8px',
                borderRadius: '8px',
              }}
            >
                <Typography variant="body1" color="primary">
                  <b>{solution_types[0].name.toUpperCase()}</b>
                </Typography>
            </div>
            </Link>
              : ''}
            <div
              style={{
                position: 'absolute',
                right: 8,
                top: 16,
                zIndex: 9,
                background: 'white',
                padding: '4px 8px',
                borderRadius: '8px',
              }}
            >
              {sdgs.map((item, index) => (
                <Link
                    to={'/all-solutions'}
                    state={{ sdgs: [`${item._id}`] }}>
                <div key={index} className={classes.sdgContainer}>
                <Image src={item.logo_square_img} srcSet={item.logo_square_img} alt="Image" className={classes.sdgImage} />
                </div>
                </Link>
                ))}
               
            </div>
            
            <div className={clsx(classes.root, className)}>
              <Link to={entityPath} >
              {images[0].src ? (
                <Image
                  src={images[0].src}
                  alt={images[0].alt}
                  srcSet={images[0].srcSet}
                  lazyProps={{ width: '100%', height: '100%' }}
                  className={classes.image}
                />
              ) : (
                ''
              )}
              </Link>
            </div>
            

            <div
              style={{
                position: 'absolute',
                left: 8,
                bottom: 16,
                zIndex: 9,
                background: 'white',
                padding: '4px 8px',
                borderRadius: '8px',
                color: 'textSecondary',
              }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
              >
                {industries[0] && (
                  <>
                     <Link
                          to={'/all-solutions'}
                          state={{ industries: [`${industries[0]._id}`] }}
                        >
                    <BusinessIcon fontSize="small" classes={{ root: classes.iconRoot }}/>{' '}
                    {industries[0].name}{'  '}
                    </Link>
                  </>
                )}
                {geography && (
                  <>
                      <Link
                          to={'/all-solutions'}
                          state={{ geographies: [geography_id] }}
                        >
                    <LanguageIcon fontSize="small" classes={{ root: classes.iconRoot }}/>{' '}
                    {geography}
                    </Link>
                  </>
                )}
              </Typography>
            </div>
          </>
        }
        cardContent={
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className={classes.chipArea}>
                {themes.map((item, index) => (
                     <Link
                          to={'/all-solutions'}
                          state={{ themes: [`${item._id}`] }}
                        >
                  <Chip key={index} color="primary" label={item.name} style={{
                        backgroundColor: `${color_chip}`,
                        color: 'white',
                      }}/>
                  </Link>
                ))}
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" color="textPrimary" align="left">
                {name}
              </Typography>
              <br />
              <Typography
                variant="subtitle1"
                color="textSecondary"
                align="left"
                classes={{ root: classes.brandText }}
              >
                <i>
                  <u>{entity[0] ? (<Link to={`/details/entity/${entity[0]._id}`}>By {entity[0].name}</Link>) : ''}</u>
                </i>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" color="textSecondary" align="left">
                <span dangerouslySetInnerHTML={{ __html: truncateString(description, 150) }} />
              </Typography>
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
}
