import React from 'react';
import { Box, Grid, Typography, Chip, Avatar } from '@material-ui/core';
import { CardProduct } from 'components/organisms';
import { Image } from 'components/atoms';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { truncateString } from 'helpers/helpers-string';
import LanguageIcon from '@material-ui/icons/Language';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    zIndex: 'auto',
  },
  image: {
    objectFit: 'cover',
  },
  sdgImage: {
    objectFit: 'contain',
  },
  sdgContainer: {
    height: '40px',
    width: '40px',
  },
  chipArea: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  brandText: {
    marginTop: '-25px',
  },
  iconRoot: {
    verticalAlign: 'middle',
  },
  logoIconRoot: {
    position: 'absolute',
    left: 8,
    bottom: 0,
    zIndex: 10,
    marginBottom: '-25px',
    width: '100px',
    height: '100px',
    padding: '4px 4px',
    background: 'white',
  },
}));

export default function CardEntity({
  id,
  images,
  name,
  description,
  tagline,
  industries,
  geographies,
  supported_solutions,
  logo_img,
  className,
}: CardEntityProps) {
  const classes = useStyles();

  var geography;

  try {
    geography = geographies[0].name;
  } catch {
    geography = null;
  }

  return (
    <Box marginBottom={2} padding={2}>
      <CardProduct
        withShadow
        liftUp
        style={{ maxWidth: 500 }}
        mediaContent={
          <>
            {supported_solutions ? (
              <div
                style={{
                  position: 'absolute',
                  left: 8,
                  top: 8,
                  zIndex: 9,
                  background: 'white',
                  padding: '4px 8px',
                  borderRadius: '8px',
                }}
              >
                <Typography variant="body1" color="primary">
                  <PeopleIcon
                    fontSize="small"
                    classes={{ root: classes.iconRoot }}
                  />{' '}
                  <b>
                    {supported_solutions.length} partner
                    {supported_solutions.length > 1 && 's'}
                  </b>
                </Typography>
              </div>
            ) : (
              ''
            )}

            <Link to={`/details/entity/${id}`}>
              <div className={clsx(classes.root, className)}>
                {images[0].src ? (
                  <Image
                    src={images[0].src}
                    alt={images[0].alt}
                    srcSet={images[0].srcSet}
                    lazyProps={{ width: '100%', height: '100%' }}
                    className={classes.image}
                  />
                ) : (
                  ''
                )}
              </div>
            </Link>
            {logo_img ? (
              <Avatar
                src={logo_img.src}
                sizes="300px"
                classes={{ root: classes.logoIconRoot }}
              />
            ) : (
              ''
            )}

            <div
              style={{
                position: 'absolute',
                left: 120,
                bottom: 4,
                zIndex: 9,
                background: 'white',
                padding: '4px 8px',
                borderRadius: '8px',
                color: 'textSecondary',
              }}
            >
              <Typography variant="body2" color="textSecondary">
                {industries[0] && (
                  <>
                    <BusinessIcon
                      fontSize="small"
                      classes={{ root: classes.iconRoot }}
                    />{' '}
                    {industries[0].name}
                    {'  '}
                  </>
                )}
                {industries[0] && geography && <br />}
                {geography && (
                  <>
                    <LanguageIcon
                      fontSize="small"
                      classes={{ root: classes.iconRoot }}
                    />{' '}
                    {geography}
                  </>
                )}
              </Typography>
            </div>
          </>
        }
        cardContent={
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" color="textPrimary" align="left">
                {name}
              </Typography>
              <br />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" color="textSecondary" align="left">
                {truncateString(description, 150)}
              </Typography>
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
}
